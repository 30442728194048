import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Table, Modal, Button } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Spinner from 'components/Spinner';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config/config';

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale
  }
}

const PhishingSimulationsModal = ({selectedSimulations, setPhishingSimulations, setSelectedSimulations, visible, onCancel, locale, setLoading, loading}: any) => {
    const [phishingSimulationTemplates, setPhishingSimulationTemplates] = useState<any>();
    const [phishingTemplate, setPhishingTemplate] = useState<any>();
    const [showTemplateModal, setShowTemplateModal] = useState(false);
    const [modalSelectedSimulations, setModalSelectedSimulations] = useState<any>();

    const intl = useIntl();

    useEffect(() => {
      const loadPhishingSimulationTemplates = async () => {
        setLoading(true);
        try {
          let response = await coursesApiClient.request('/api/v1/courses/phishing/phishing-simulation', [], 'GET');
          const phishingTemplates = response.phishingSimulations;
          let simulations:any = [];
          let i = 0;
          Object.values(phishingTemplates).map((el:any)=> {
            if (el.phishingTemplateCombinations.length > 0) {
              simulations.push(el)
              el.index = (i = i + 1)
            }
            return null;
          })
          setPhishingSimulations(simulations)
          setPhishingSimulationTemplates(simulations);
        } catch (error) {
          message.error(intl.formatMessage({id: "error.server_error"}));
        } finally {
          setLoading(false);
        }
      }
      loadPhishingSimulationTemplates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    useEffect(()=>{
      if (!modalSelectedSimulations && selectedSimulations && phishingSimulationTemplates) {
        let simulations:any = [];
        Object.values(selectedSimulations).map((element:any)=>{
          Object.values(phishingSimulationTemplates).map((el:any)=>{
            if (element.id === el.id) {
              simulations.push(el);
              if (element.disabled) {
                el.disabled = element.disabled
              }
            }
            return simulations;
          })
          return simulations;
        })

        setModalSelectedSimulations(simulations);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedSimulations])

    const loadPhishingTemplate = async (templateId:any) => {
      let response = await coursesApiClient.request(`/api/v1/courses/gophish/templates/${templateId}`, [], 'GET');
      setPhishingTemplate(response.gophishTemplate);
      setShowTemplateModal(true);
    }

    const loadPhishingLandingPage = async (landingPageId:any) => {
      let response = await coursesApiClient.request(`/api/v1/courses/gophish/pages/${landingPageId}`, [], 'GET');

      const newWindow: any = window.open("", response.gophishPage.name);
      newWindow.document.write(response.gophishPage.html);
    }

    const columns = [
      {
        title: intl.formatMessage({id: 'phishing.phishing_simulation_template'}),
        dataIndex: 'simulation_id',
        render: (value: string, record: any) => {
          let templateName:any = null
          Object.values(record.phishingTemplateCombinations).map((el:any)=> {
            if (el.language === locale.locale) {
              templateName = el.simulation_template_name
            } else if (!templateName && el.language ==='EN') {
              templateName = el.simulation_template_name
            } else if (record.phishingTemplateCombinations.length === 1) {
              templateName = el.simulation_template_name
            }
            return templateName;
          })
          return templateName
        }
      },
      {
        title: intl.formatMessage({id: 'phishing.difficulty'}),
        dataIndex: 'difficulty',
        render: (difficulty: string) => <FormattedMessage id={`phishing.${difficulty.toLowerCase()}`} />
      },
      {
        title: intl.formatMessage({id: 'phishing.supported_languages'}),
        dataIndex: 'template_id',
        render: (value: string, record: any) => {
          let supportedLanguages:any = [];
          Object.values(record.phishingTemplateCombinations).map((el:any) => {
            return supportedLanguages.push(el.language)
          })
          return supportedLanguages.map((el:any) => el).join(', ');
        }
      },
      {
        title: intl.formatMessage({id: 'phishing.preview_phishing_template'}),
        dataIndex: 'template_id',
        render: (value: string, record: any) => {
          let supportedLanguages:any = [];
          Object.values(record.phishingTemplateCombinations).map((el:any) => {
            return supportedLanguages.push([el.language, el.gophish_template_id])
          })
          return supportedLanguages.map((el:any) => <Button type='link' onClick={()=>loadPhishingTemplate(el[1])}>{el[0]}</Button>)
        }
      },
      {
        title: intl.formatMessage({id: 'phishing.preview_phishing_landing_page'}),
        dataIndex: 'template_id',
        render: (value: string, record: any) => {
          let supportedLanguages:any = [];
          Object.values(record.phishingTemplateCombinations).map((el:any) => {
            return supportedLanguages.push([el.language, el.gophish_landing_page_id])
          })
          return supportedLanguages.map((el:any) => <Button type='link' onClick={()=>loadPhishingLandingPage(el[1])}>{el[0]}</Button>)
        }
      }
    ];

    const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`
    };

  const rowSelection = {
    selectedRowKeys: modalSelectedSimulations,
    onChange: (selectedRows:any) => {
      setModalSelectedSimulations(selectedRows);
    },
    getCheckboxProps: (record:any) => ({
      disabled: record.disabled
    }),
  };

  const selectSimulations = () => {
    let simulations:any = [];
    let selectedSimulationsArray:any = [];
    let nextSimulations:any = [];

    Object.values(modalSelectedSimulations).map((el:any) => {
      return nextSimulations.push(el.id);
    })

    if (selectedSimulations) {
      Object.values(selectedSimulations).map((el:any) => {
        if (nextSimulations.includes(el.id)) {
          simulations.push(el);
          let index = nextSimulations.indexOf(el.id);
          if (index > -1) {
            nextSimulations.splice(index, 1);
          }
        }
        return nextSimulations;
      })
    }

    Object.values(modalSelectedSimulations).map((el:any) => {
      if (nextSimulations.includes(el.id)) {
        simulations.push(el);
      }
      return simulations;
    })

    Object.values(simulations).map((el:any) => {
      if (simulations.includes(el)) {
        selectedSimulationsArray.push(el);
      }
      return selectedSimulationsArray;
    })

    setSelectedSimulations(selectedSimulationsArray);
    onCancel(true)
  }

  return (
    <>
    <Modal
        className="results-modal"
        title={intl.formatMessage({id: 'phishing.phishing_simulations_modal'})}
        visible={visible}
        onCancel={onCancel}
        onOk={()=>selectSimulations()}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
        okText={intl.formatMessage({id: 'general.select'})}
    >
      <Spinner spinning={loading}>
          <Table
              locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
              pagination={{
                showTotal: showTotal,
                pageSizeOptions: ['5'],
                showSizeChanger: true,
                locale: { items_per_page: intl.formatMessage({id: "general.page"}), jump_to: intl.formatMessage({id: "general.go_to"}) },
                showQuickJumper: true
              }}
              rowSelection={rowSelection}
              rowKey={(record) => record}
              style={{marginTop: 16}}
              columns={columns}
              dataSource={phishingSimulationTemplates}
              size="middle"
              scroll={{ x: 800 }}
          />
      </Spinner>
    </Modal>
    <Modal
      className="results-modal"
      title={intl.formatMessage({id: 'phishing.phishing_template'})}
      visible={showTemplateModal}
      onCancel={() => setShowTemplateModal(false)}
      footer={
        [
          <Button  onClick={() => setShowTemplateModal(false)}>
            <FormattedMessage id="general.cancel" />
          </Button>
        ]
      }
    >
      {phishingTemplate ?
      <Editor
        apiKey={config.api.tinymceApiKey}
        disabled={true}
        value={phishingTemplate.html}
        init={{
          height: 500,
          relative_urls : true,
          remove_script_host : true,
          menubar: false,
          plugins: [],
          toolbar:''
      }}
    />
    :
    null
      }
    </Modal>
    </>
  )
}
export default connect(mapStateToProps)(withRouter(PhishingSimulationsModal));
