import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {message, Modal, Form, Radio} from 'antd';
import apiClient from 'utils/apiClient';
import FileDownload from 'js-file-download';
import {UsersExportModalInterface} from '../types';

const UsersExportModal: React.FC<UsersExportModalInterface> = ({visible, onCancel, customerId, afterSubmit, userRole, rowSelection, selectAllUsers, selectedRowsLength}) => {
    const [exportLoading, serExportLoading] = useState(false);

    const intl = useIntl();
    const [exportForm] = Form.useForm();

    const exportCustomerUsersForm = async (values: any) => {
        if (customerId){
            const bodyFormData = new FormData();

            if (values.exportOption === 'selected' && !selectAllUsers) {
                if (rowSelection.selectedUsers.length) {
                    bodyFormData.append('selectedUsers', rowSelection.selectedUsers.join(','));
                    bodyFormData.append('customerId', customerId.toString());
                    bodyFormData.append('language', 'EN');
                } else {
                    message.error(intl.formatMessage({id: "campaign.please_select_atleast_one_user"}));
                    return;
                }
            } else {
                bodyFormData.append('selectedUsers', '');
                bodyFormData.append('customerId', customerId.toString());
                bodyFormData.append('language', 'EN');
            }
            serExportLoading(true);
            try {
                let exportResponse = await apiClient.request('/api/v1/auth/users-export', bodyFormData, 'POST', true, true);
                FileDownload(exportResponse, 'CloudStudy-Customer-Users.xlsx');
                message.success(intl.formatMessage({id: "users.export.successful_export_in_message"}));
                serExportLoading(false);
            } catch (e) {
                console.error(e);
                message.error(intl.formatMessage({id: "error.data_load"}));
                serExportLoading(false);
            }

        } else {
            message.error(intl.formatMessage({id: "users.select_customer_in_filter_first"}));
            serExportLoading(false);
        }

        onCancel();
    };

    return (
        <Modal
            className="user-list-modal"
            title={intl.formatMessage({id: 'users.export.export'})}
            visible={visible}
            onOk={() => {
                exportForm.submit();
            }}
            confirmLoading={exportLoading}
            onCancel={onCancel}
            okText={intl.formatMessage({id: 'general.export.send'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
            <Form form={exportForm} onFinish={exportCustomerUsersForm}>
                <Form.Item
                    name="exportOption"
                    label={intl.formatMessage({id: 'users.export.export_option'})}
                >
                    <Radio.Group defaultValue='all'>
                        <Radio value="all"><FormattedMessage id='users.export.export_all_users'/></Radio>
                        <Radio disabled={selectAllUsers} value="selected"><FormattedMessage id={intl.formatMessage({id: 'users.export.export_selected_users'}, {amount: rowSelection.selectedUsers.length ? rowSelection.selectedUsers.length : selectedRowsLength})}/></Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )
};

export default UsersExportModal;
